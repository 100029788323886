import React, { useState } from "react";

import servicesPriceData from "../../data/pricecalculatordata/servicesPriceData.json";
import { toastEmitter } from "../toaster/ToastComponent";



const config = servicesPriceData;

const FormFour = ({sendDataToParent, _scrollToSection}) => {


  
/// calculator logic


const [defaultValueOptions, setSelectedOptions] = useState({
    gameplayTime: '',
    genre: '',
    platform: '',
    mode: '',
    android: '',
    ios:'',

  });

  const handleChangeCheckbox = (e) => {

    
        setSelectedOptions({
            ...defaultValueOptions,
            [e.target.name]: (e.target.checked === true ? (e.target.name) : (""))
          });
          //console.log(defaultValueOptions);
    
  };

  const handleChange = (e) => {
    setSelectedOptions({
      ...defaultValueOptions,
      [e.target.name]: e.target.value
    });
  };

  

const calculatePrice = (event) => {
    event.preventDefault();

    //console.log(defaultValueOptions);
    

    if (defaultValueOptions.genre === '') {
      toastEmitter('error', 'Select game genre first.')      
      return;
    }
    if (defaultValueOptions.gameplayTime === '') {
      toastEmitter('error', 'Select gameplay time.')      
      return;
    }





    let totalPrice = 0;

    // calculate factor
    const k = 10;
    const n = 0.25159;
    

    //platforms
    let factorAdded = false;
    let android = false;
    let ios = false;


    Object.values(defaultValueOptions).forEach(option => {
      const configItem = config.find(item => item.id === option);

      //console.log(configItem);
      if (configItem.id === 'android') {
        android = true;
      }
      if (configItem.id === 'ios') {
        ios = true;
      }

      if (configItem) {
        if(totalPrice === 0){
        totalPrice += configItem['fixed-price'];
        //totalFactor *= configItem['data-factor'];

        } else {

        
        totalPrice *= configItem['data-factor'];
        totalPrice += configItem['fixed-price'];

        if(android && ios && !factorAdded){
            //console.log("Factor to be used at price:" + totalPrice + " = " + k / Math.pow(totalPrice, Math.abs(n)));

            totalPrice *= (k / Math.pow(totalPrice, Math.abs(n)));

            factorAdded = true;
        }
    }
      }
    });

    sendDataToParent(totalPrice.toFixed(0) , defaultValueOptions);
    _scrollToSection();

  };


  return (
    <form  className="axil-contact-form">
      <div className="dropdownSelectContainer">
        <div className="form-group dropdownGropuContainer">
          <label>Main Genre</label>
          <select
            className="form-control dropdownSelect"
            name="genre"
            onChange={handleChange}
            required
          >
            <option value="select" disabled selected>
              Select Game Genre
            </option>
            <option value="action">Action</option>
            <option value="puzzle">Puzzle</option>
            <option value="arcade">Arcade</option>
            <option value="casual">Casual</option>
            <option value="towerDefence">Tower Defence</option>
            <option value="shooter">Shooter</option>
            <option value="idle">Idle</option>
            <option value="sorter">Sorter</option>
            <option value="rpg">RPG</option>
            <option value="ccg">CCG</option>
            <option value="strategy">Strategy</option>
            <option value="hyperCasual">Hyper-Casual</option>
            <option value="hybridCasual">Hybrid-Casual</option>
            <option value="simulator">Simulator</option>
            <option value="racing">Racing</option>
            <option value="fighting">Fighting</option>
          </select>
        </div>
        <div className="form-group dropdownGropuContainer">
          <label>Gameplay Time</label>
          <select
            className="form-control dropdownSelect"
            name="gameplayTime"
            onChange={handleChange}
            required
          >
            <option value="select" disabled selected>
              Select Gameplay Time
            </option>
            <option value="30m">Upto 30 min [demo or prototype]</option>
            <option value="2h">2 hours</option>
            <option value="5h">5 hours [simple mobile game]</option>
            <option value="10h">10 hour</option>
            <option value="25h">25 hours [midcore project starts here]</option>
            <option value="40h">40 hours</option>
            <option value="60h">60+ hours [AAA projects starts here]</option>
          </select>
        </div>
        <div className="form-group dropdownGropuContainer">
          <label>Game Engine</label>
          <select
            className="form-control dropdownSelect"
            name="game-engine"
            onChange={handleChange}
            required
          >
            <option value="select" disabled selected>
              Select Game Engine
            </option>
            <option value="unity">Unity</option>
            <option value="unreal" disabled>
              Unreal Engine
            </option>
          </select>
        </div>
        <div className="form-group dropdownGropuContainer">
          <label>Game Modes</label>
          <select
            className="form-control dropdownSelect"
            name="game-modes"
            onChange={handleChange}
            required
          >
            <option value="select" disabled selected>
              Select a Game Mode
            </option>
            <option value="single-player">Single-player</option>
            <option value="co-op">Co-op (multiple players, one device)</option>
            <option value="turn-based-multiplayer">
              Turn based multiplayer (online)
            </option>
            <option value="realtime-multiplayer">
              Realtime action multiplayer (online)
            </option>
          </select>
        </div>
      </div>

      <h6 className=" mt--50">
        <b>Platforms</b>
      </h6>
      <div className="dropdownSelectContainer ">
        <div className="toogleGropuContainer">
          <div className="checkbox_item citem_1">
            <label className="checkbox_wrap">
              <input type="checkbox" name="android" className="checkbox_inp"  onChange={handleChangeCheckbox} />
              <span className="checkbox_mark"></span>
            </label>
          </div>
          <label className="textCheckboxLabel">Android</label>
        </div>
        <div className="toogleGropuContainer">
          <div className="checkbox_item citem_1">
            <label className="checkbox_wrap">
              <input type="checkbox" name="ios" className="checkbox_inp" onChange={handleChangeCheckbox}/>
              <span className="checkbox_mark"></span>
            </label>
          </div>
          <label className="textCheckboxLabel">iOS</label>
        </div>
        <div className="toogleGropuContainer">
          <div className="checkbox_item citem_1">
            <label className="checkbox_wrap">
              <input type="checkbox" name="xbox" className="checkbox_inp" onChange={handleChangeCheckbox} />
              <span className="checkbox_mark"></span>
            </label>
          </div>
          <label className="textCheckboxLabel">XBox</label>
        </div>
        <div className="toogleGropuContainer">
          <div className="checkbox_item citem_1">
            <label className="checkbox_wrap">
              <input type="checkbox" name="playstation" className="checkbox_inp" onChange={handleChangeCheckbox}/>
              <span className="checkbox_mark"></span>
            </label>
          </div>
          <label className="textCheckboxLabel">PlayStation</label>
        </div>
        <div className="toogleGropuContainer">
          <div className="checkbox_item citem_1">
            <label className="checkbox_wrap">
              <input type="checkbox" name="nintindo" className="checkbox_inp" onChange={handleChangeCheckbox} />
              <span className="checkbox_mark"></span>
            </label>
          </div>
          <label className="textCheckboxLabel">Nintendo</label>
        </div>
      </div>

      <div className="dropdownSelectContainer mt--80 ">
        <div className="form-group dropdownGropuContainer">
          <label>Gameplay Mechanics</label>
          <select
            className="form-control dropdownSelect"
            onChange={handleChange}
            name="gameplay-mechanics"
            required
          >
            <option value="select" disabled selected>
              Select Gameplay Mechanics
            </option>
            <option value="single-easy">Single & easy</option>
            <option value="single-complex">Single & complex</option>
            <option value="multiple-easy">Multiple & easy</option>
            <option value="multiple-complex">Multiple & complex</option>
          </select>
        </div>
        <div className="form-group dropdownGropuContainer">
          <label>Graphic Style</label>
          <select
            className="form-control dropdownSelect"
            onChange={handleChange}
            name="graphic-style"
            required
          >
            <option value="select" disabled selected>
              Select Graphic Style
            </option>
            <option value="cartoon-stylized">Cartoon or stylized</option>
            <option value="realistic-graphics">Realistic</option>
            <option value="hyper-realistic-graphics">Hyperrealistic</option>
          </select>
        </div>
        <div className="form-group dropdownGropuContainer">
          <label>SFX</label>
          <select
            className="form-control dropdownSelect"
            name="sfx"
            onChange={handleChange}
            required
          >
            <option value="select" disabled selected>
              Select SFX Option
            </option>
            <option value="no-sfx">No SFX</option>
            <option value="free-to-use-sfx" >
            Free to use SFX
            </option>
            <option value="custom-sfx" >
            Original SFX
            </option>
          </select>
        </div>
        <div className="form-group dropdownGropuContainer">
          <label>Music</label>
          <select
            className="form-control dropdownSelect"
            name="music"
            onChange={handleChange}
            required
          >
            <option value="select" disabled selected>
              Select Music Option
            </option>
            <option value="no-music">No music</option>
            <option value="free-to-use-music">Free to use music</option>
            <option value="custom-music">
            Original music
            </option>
           
          </select>
        </div>
      </div>

      <h6 className=" mt--50">
        <b>Additional services</b>
      </h6>
      <div className="dropdownSelectContainer ">
        <div className="toogleGropuContainerServices">
          <div className="checkbox_item citem_1">
            <label className="checkbox_wrap">
              <input type="checkbox" name="nftIntegration" className="checkbox_inp" onChange={handleChangeCheckbox} />
              <span className="checkbox_mark"></span>
            </label>
          </div>
          <label className="textCheckboxLabel">NFT/Blockchain integration</label>
        </div>
        <div className="toogleGropuContainerServices">
          <div className="checkbox_item citem_1">
            <label className="checkbox_wrap">
              <input type="checkbox" name="promoMaterial" className="checkbox_inp" onChange={handleChangeCheckbox}/>
              <span className="checkbox_mark"></span>
            </label>
          </div>
          <label className="textCheckboxLabel">Promo materials (images, texts, etc.)</label>
        </div>
        <div className="toogleGropuContainerServices">
          <div className="checkbox_item citem_1">
            <label className="checkbox_wrap">
              <input type="checkbox" name="storeRelease" className="checkbox_inp" onChange={handleChangeCheckbox} />
              <span className="checkbox_mark"></span>
            </label>
          </div>
          <label className="textCheckboxLabel">Store release support</label>
        </div>
        <div className="toogleGropuContainerServices">
          <div className="checkbox_item citem_1">
            <label className="checkbox_wrap">
              <input type="checkbox" name="tpsIntegration" className="checkbox_inp" onChange={handleChangeCheckbox} />
              <span className="checkbox_mark"></span>
            </label>
          </div>
          <label className="textCheckboxLabel">Third party services integration</label>
        </div>
        <div className="toogleGropuContainerServices">
          <div className="checkbox_item citem_1">
            <label className="checkbox_wrap">
              <input type="checkbox" name="ar-vr-technology" className="checkbox_inp" onChange={handleChangeCheckbox} />
              <span className="checkbox_mark"></span>
            </label>
          </div>
          <label className="textCheckboxLabel">AR/VR Technology</label>
        </div>
      </div>
      <div className="buttonHolder mt--80 mb--40">
        <button
          type="submit"
          className="calculate-btn btn-fill-primary  btn-primary"
          name="submit-btn"
          onClick={calculatePrice}
        >
          Calculate & Compare
        </button>
       
      </div>
    </form>
    
  );
};

export default FormFour;
