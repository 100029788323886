import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF,  FaLinkedin, FaInstagram,  FaEnvelopeOpen, FaTiktok, FaYoutube } from "react-icons/fa";
import ServiceData from "../../data/service/ServiceMain.json";
import { slugify } from '../../utils';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import useRecaptcha from '../../component/Recapcha/useRecaptcha';

import { toastEmitter } from '../../component/toaster/ToastComponent';

const getServiceData = ServiceData;

const FooterOne = ({parentClass}) => {


    const { capchaToken, recaptchaRef, handleRecaptcha } = useRecaptcha();


    async function subscribeNewsletter(event) {

        event.preventDefault();

        let userEmail = document.getElementById('newsletterInput').value;

        let data = {
            "email": userEmail,
            "g-recaptcha-response": capchaToken
        }

        if (!capchaToken){
            return;
        }

        if (userEmail !== "") {

            try {
                
                // Send subscribe request with captcha token, email
            const result = await axios.post("https://api.subrays.com/subscribe_newsletter.php", data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
      
            console.log(result);
            // Check if the reCAPTCHA validation failed on the server-side
            if (result === 'reCAPTCHA verification failed') {
            toastEmitter('error', "reCAPTCHA verification failed");

              handleRecaptcha('');
              if (recaptchaRef.current) {
                recaptchaRef.current.reset();
              }
              return;
            }
      
            // Reset captcha after submission
            recaptchaRef.current.reset();
      
            // If the login is successful, perform post-login logic
            if (result === "Email already exists") {
              
                toastEmitter('error', "Already Subscribed");
              // ...
            } 
            if (result === "New record created successfully") {
              
                toastEmitter('success', "Subscribed");
              // ...
            } 

            } catch (error) {
                toastEmitter('error', "some error occurred.");

            }

            
          } else {
            toastEmitter('error', "Please enter your email.");

          }
        }   



    return (
        <footer className={`footer-area ${parentClass}`}>
           
            <div className="container">
                <div className="footer-top">
                    <div className="footer-social-link">
                        <ul className="list-unstyled">
                            <li><a href="https://www.facebook.com/subrays.technologies"><FaFacebookF /></a></li>
                            <li><a href="https://www.tiktok.com/@subrays.technologies"><FaTiktok /></a></li>
                            <li><a href="https://www.linkedin.com/company/subrays/"><FaLinkedin /></a></li>
                            <li><a href="https://www.instagram.com/subrays.technologies/"><FaInstagram /></a></li>
                            <li><a href="https://www.youtube.com/@subraystechnologies"><FaYoutube /></a></li>

                            {/** <li><Link to="https://twitter.com/"><FaXTwitter /></Link></li>
                            <li><Link to="https://www.pinterest.com/"><FaPinterestP /></Link></li>
                            <li><Link to="https://vimeo.com/"><FaVimeoV /></Link></li>
                            <li><Link to="https://dribbble.com/"><FaDribbble /></Link></li>
                            <li><Link to="https://www.behance.net/"><FaBehance /></Link></li>*/}
                        </ul>
                    </div>
                </div>
                <div className="footer-main">
                    <div className="row">
                        <div className="col-xl-6 col-lg-5">
                            <div className="footer-widget border-end">
                                <div className="footer-newsletter">
                                    <h2 className="title">Get in touch!</h2>
                                    <p>Join our community! Enter your email to get the latest updates and exclusive content.</p>
                                    <form onSubmit={subscribeNewsletter}>
                                        <div className="input-group">
                                            <span className="mail-icon"><FaEnvelopeOpen /> </span>
                                            <input type="email" id='newsletterInput' className="form-control" placeholder="Email address" />
                                            <button className="subscribe-btn" type="submit" disabled={!capchaToken} >Subscribe</button>
                                            
                                        </div>
                                        <ReCAPTCHA
                                                style={{paddingTop: "10px"}}
                                                ref={recaptchaRef}
                                                sitekey="6LeeXSIqAAAAACKOOwpauR1sv589Dnk2Mt0sQxBF"
                                                onChange={handleRecaptcha}
                                            />
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-7">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Services</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                {getServiceData.slice(0, 6).map((data, index) => (
                                                    <li key={index}>
                                                        <Link to={process.env.PUBLIC_URL + `/service-details/${slugify(data.title)}`}>{data.title}</Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Resourses</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                            <li> <Link to={process.env.PUBLIC_URL + "/game-development-price-calculator"}>Price Calculator</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + "/our-games"}>Our Games</Link></li>
                                                <li> <Link to={process.env.PUBLIC_URL + "/about-us"}>About us</Link></li>
                                                
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Support</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link></li>
                                                <li> <Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + "/terms-use"}>Terms of Use</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom"> 
                    <div className="row">
                        <div className="col-md-6">
                            <div className="footer-copyright">
                                <span className="copyright-text">© {new Date().getFullYear()}. All rights reserved by <a href="/">Subrays</a>.</span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="footer-bottom-link">
                                <ul className="list-unstyled">
                                    <li><Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/terms-use"}>Terms of Use</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterOne;