import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const PrivacyPolicy = () => {

    return (
        <>
            <SEO title="Privacy Policy" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                title="Privacy Policy"
                page="Privacy Policy"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                <div className="section-title">
                                
<p>Last updated: January 05, 2024</p>
<p>This Privacy Policy explains how information about you is collected, used, and disclosed by Subrays Technologies (Private) Limited and its subsidiaries (“Website”, “Mobile Application”, “Mobile Game”, “The App”, “Services” “we” or “us”). This Privacy Policy applies to information we collect when you use our websites, mobile applications, games, and other online products, properties, and services (collectively, the “Service(s)”). This Privacy Policy does not apply to the information collected from web-based applications or mobile applications that are published by third parties.</p>
    
    <p>Subrays Technologies (Private) Limited's Website, Mobile Applications, and Services are based in Pakistan and the information we collect is governed by Pakistan's law. By accessing or using the Services or otherwise providing information to us, you consent to the processing and transfer of information in and to Pakistan and other countries.</p>
    
    <p>This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decides to use any of our Services.</p>
    
    <p>If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</p>
    
    <p>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at Services unless otherwise defined in this Privacy Policy.</p>
    
    <h4>Information Collection and Use</h4>
    <p>For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to device-identifying data. The information that we request will be retained on your device and is not collected by us in any way.</p>
    
    <p>The app does use third-party services that may collect information used to identify you.</p>
    
    <h4>Link to privacy policy of third-party service providers used by the app</h4>
    <ul>
        <li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google Play Services</a></li>
        <li><a href="https://www.facebook.com/about/privacy/update/printable" target="_blank" rel="noopener noreferrer">Facebook</a></li>
        <li><a href="https://gameanalytics.com/privacy" target="_blank" rel="noopener noreferrer">GameAnalytics</a></li>
        <li><a href="https://policies.google.com/privacy?hl=en-US" target="_blank" rel="noopener noreferrer">GoogleAnalytics</a></li>
        <li><a href="https://www.applovin.com/privacy/" target="_blank" rel="noopener noreferrer">AppLovin</a></li>
        <li><a href="https://www.appsflyer.com/legal/services-privacy-policy/" target="_blank" rel="noopener noreferrer">AppsFlyer</a></li>
    </ul>
    
    <h4>Log Data</h4>
    <p>We want to inform you that whenever you use our Service, in the case of an error in the app we collect data and information (through third-party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.</p>
    
    <h4>Cookies</h4>
    <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.</p>
    
    <p>This Service does not use these “cookies” explicitly. However, the app may use third-party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.</p>
    
    <h4>Service Providers</h4>
    <p>We may employ third-party companies and individuals due to the following reasons:</p>
    <ul>
        <li>To facilitate our Service;</li>
        <li>To provide the Service on our behalf;</li>
        <li>To perform Service-related services; or</li>
        <li>To assist us in analyzing how our Service is used.</li>
    </ul>
    
    <p>We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>
    
    <h4>Security</h4>
    <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>
    
    <h4>Links to Other Sites or Services</h4>
    <p>This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
    
    <h4>Children’s Privacy</h4>
    <p>These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13 years of age. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.</p>
    
    <h4>Changes to This Policy</h4>
    <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
    
    <p>This policy is effective as of January 05, 2024.</p>
<h4>Contact Us</h4>
<p>If you have any questions about this Privacy Policy, You can contact us:</p>
<ul>
<li>
<p>By email: hello@subrays.com</p>
</li>
<li>
<p>By visiting this page on our website: <a href="https://subrays.com/contact" rel="noopener noreferrer" target="_blank">https://subrays.com/contact</a></p>
</li>
<li>
<p>By phone number: +92 (040) 203 5022</p>
</li>
</ul>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CtaLayoutOne />
            <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default PrivacyPolicy;