import React, { useRef } from "react";

import { toastEmitter } from "../toaster/ToastComponent";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import useRecaptcha from "../../component/Recapcha/useRecaptcha";

const FormFive = ({priceCalulated,_optionsSelected}) => {
  const form = useRef();
  const { capchaToken, recaptchaRef, handleRecaptcha } = useRecaptcha();

    async function sendEmail(e) {
    e.preventDefault();

    //let contactName = document.getElementById('contact-name').value;
    let contactEmail = document.getElementById('contact-email').value;
    //let contactPhone = document.getElementById('contact-phone').value;
    //let contactMessage = document.getElementById('contact-message').value;

    //console.log(_optionsSelected);
    _optionsSelected.Source = "Price Calculator Page";
    //_optionsSelected.Phone = contactPhone;
    _optionsSelected.priceCalulatedCompetitiors = priceCalulated;
    _optionsSelected.priceCalulatedFreelancers = priceCalulated * 0.7;
    _optionsSelected.priceCalulatedSubrays = priceCalulated * 0.5;

      

    //get the ip and country

    await axios.get('https://api.ipify.org?format=json')
      .then(response => {
        const ip = response.data.ip;

        // Fetch country based on IP address
        return axios.get(`https://ipapi.co/${ip}/json/`);
      })
      .then(response => {
        _optionsSelected.start = '----------------------------------USER DATA START---------------------------------';

        _optionsSelected.userCountry = response.data.country_name;
        _optionsSelected.userCity = response.data.city;
        _optionsSelected.userIP = response.data.ip;
        _optionsSelected.userTimezone = response.data.timezone;
        _optionsSelected.userRegion = response.data.region;
        _optionsSelected.userCurrency = response.data.currency;
        _optionsSelected.userCalling_Code = response.data.country_calling_code;

        _optionsSelected.end = '----------------------------------USER DATA END---------------------------------';

      })
      .catch(error => {
        console.error("Error fetching the IP and country: ", error);
      });
      

      

    let data = {
        "name": 'No Name',
        "email": contactEmail,
        "message": 'No Message',
        "data": _optionsSelected,
        "g-recaptcha-response": capchaToken
      }


      if (!capchaToken){
        toastEmitter("error", "reCAPTCHA verification failed");
        return;
    }

    try {
        // Send query request with captcha token, email
        const result = await axios.post("https://api.subrays.com/send_mail.php", data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

       console.log(data);

        if (result.data === 'reCAPTCHA verification failed.') {
            toastEmitter('error', "reCAPTCHA verification failed.");

              handleRecaptcha('');
              if (recaptchaRef.current) {
                recaptchaRef.current.reset();
              }
              return;
            }
            if (result.data === 'Invalid JSON input.') {
                toastEmitter('error', "some error occurred.");
    
                  handleRecaptcha('');
                  if (recaptchaRef.current) {
                    recaptchaRef.current.reset();
                  }
                  return;
                }
                if (result.data === 'Mail sent successfully.') {
                    toastEmitter("success", "We have received your online inquiry and forwarded your information to one of our Account Directors who will be in touch with you shortly.");
                    if (window.gtag_report_conversion) {
                      
                      window.gtag_report_conversion();
                    }
        
                      handleRecaptcha('');
                      if (recaptchaRef.current) {
                        recaptchaRef.current.reset();
                      }
                      return;
                    }

                
        
  
    } catch (error) {
        toastEmitter('error', "some error occurred.");
        recaptchaRef.current.reset();
              
    }
 

    
  };


 

  return (
    <form ref={form} onSubmit={sendEmail} className="comparisonTabelcontactContainer">
      
      <div className="form-group emailContainer">
        <label>Let's discuss the project</label>
        <input
        id="contact-email"
          type="email"
          className="form-control emailInput"
          
          name="contact-email"
          placeholder="email@xyz.com"
          required
        />
      </div>
      <div className="form-group  buttonAndCaptcha "  >
        <ReCAPTCHA
          
          ref={recaptchaRef}
          sitekey="6LeeXSIqAAAAACKOOwpauR1sv589Dnk2Mt0sQxBF"
          onChange={handleRecaptcha}
        />
        <button
        disabled={!capchaToken}
          type="submit"
          className="comparionTableContactButton"
          name="submit-btn"
        >
          Get Started
        </button>
      </div>
      <div className="form-group">
        
      </div>
      
     

      
      <div className="disclaimerTest ">
            
            <span><b>*</b> We respect your privacy and will not share your information with third parties.</span>
        </div>
    </form>
  );
};

export default FormFive;
