import React from 'react'
import PropTypes from "prop-types";


const SEO = ( {title} ) => {
    return (
        <>
            <meta charSet="utf-8" />
            <title>Subrays | {title}</title>
            <meta name="robots" content="noindex, follow" />
            <meta name="description" content="Subrays Technologies PVT. LTD. is a game development company based in Pakistan that specializes in designing, developing, and maintaining digital games for consumers. We have been successfully establishing and conducting remote operations since 2016, and now, marking a significant milestone, we have set up our onsite office in the last quarter of December 2023. As a company, we continue to grow and innovate in the tech industry." />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;