import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderTwo from '../common/header/HeaderTwo';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import ProcessOne from '../component/process/ProcessOne';
import TeamOne from '../component/team/TeamOne';
import FaqOne from '../component/faq/FaqOne';
import AboutFour from '../component/about/AboutFour';
import AboutFive from '../component/about/AboutFive';


const AboutUs = () => {

    return (
        <>
        <SEO title="About us" />
        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderTwo />
                <BcrumbBannerOne 
                title="One of the fastest growing company"
                paragraph ="We design and develop web and mobile games for our clients worldwide."
                styleClass="thumbnail-4"
                mainThumb="/images/banner/banner-about-island.svg"
                />
                <AboutFour />
                <TeamOne />
                <AboutFive />
                <ProcessOne />
                <FaqOne />
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default AboutUs;