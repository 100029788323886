import React from 'react'
import toast, { Toaster } from 'react-hot-toast'

import 'react-toastify/dist/ReactToastify.css'

export function capitalizeFirstLetter(sentence) {
  // Split the sentence into an array of words
  if (!sentence) return '';

  // Capitalize the first letter of each word
  return sentence.split(' ').map(word =>
    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  ).join(' ');

}

export const toastEmitter = (type, message) => {
  if (type === 'success') {
    return toast.success(capitalizeFirstLetter(message), {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored'
    })
  }
  if (type === 'error') {
    return toast.error(capitalizeFirstLetter(message), {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored'
    })
  }
  
  
}

export default function ToastComponent () {
  return (
    <Toaster
      position='top-right'
      reverseOrder={false}
      gutter={8}
      containerClassName=''
      containerStyle={{}}
      toastOptions={{
        // Define default options
        className: 'mt--80',
        duration: 5000,
        style: {
          zIndex: 10000
        },

        // Default options for specific types
        success: {
          duration: 3000
          // theme: {
          //   primary: 'green',
          //   secondary: 'black',
          // },
        }
      }}
    />
  )
}
