import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderTwo from '../common/header/HeaderTwo';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ProjectOne from '../component/project/ProjectOne';


const ProjectGridOne = () => {

    return (
        <>
        <SEO title="Our Games" />
        <ColorSwitcher />
        <main className="main-wrapper">
            <HeaderTwo />
            <BcrumbBannerOne 
                title="Our Games"
                paragraph ="Step into our worlds, meticulously designed to offer you thrilling and immersive gameplay."
                styleClass=""
                mainThumb="/images/banner/banner-thumb-1.svg"
            />
            <ProjectOne />
            <CtaLayoutOne />
            <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default ProjectGridOne;