import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';

const Datas = [
    {
        id: 1,
        title: "Extensive Expertise",
        para: "Our team boasts deep industry knowledge and years of experience in game development, ensuring top-tier quality in every project."
    },
    {
        id: 2,
        title: "Proven Track Record",
        para: "Our portfolio is filled with successful projects and satisfied clients, demonstrating our ability to deliver outstanding results."
    },
    {
        id: 3,
        title: "Competitive Pricing",
        para: "We provide high-quality game development services at competitive prices, offering exceptional value for your investment."
    },
    {
        id: 4,
        title: "Passion for Excellence",
        para: "We are driven by a passion for excellence, constantly pushing the boundaries to create games that captivate and inspire."
    },
    {
        id: 5,
        title: "Comprehensive Services",
        para: "From concept to launch, we offer a full suite of services to meet all your game development needs under one roof."
    },
    {
        id: 6,
        title: "Post-Launch Support",
        para: "Our commitment doesn't end at launch; we offer ongoing support to ensure your game continues to thrive."
    }
]




const AboutThree = () => {
    return (
        <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
            <div className="container">
                <SectionTitle 
                    subtitle="Why Subrays"
                    title="Why should you work with us?"
                    description="We maintain high standards, guaranteeing that our games are well-designed, bug-free, and offer a unified user experience."
                    textAlignment="heading-left heading-light-left mb--100"
                    textColor=""
                />

                <div className="row">
                    {Datas.map((data) => (
                        <div className="col-lg-4" key={data.id}>
                            <div className="about-quality">
                                <h3 className="sl-number">{data.id}</h3>
                                <h5 className="title">{data.title}</h5>
                                <p>{data.para}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" /></li>
            </ul>
        </div>
    )
}

export default AboutThree;