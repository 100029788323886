import React from 'react';
import { Link } from 'react-router-dom';


const AboutFive = () => {
    return (
      <div className="section-padding-equal">
            <div className="container">
                <div className="row align-items-center">
                <div className="col-lg-7">
                    <div className="about-team">
                    <div className="thumbnail">
                        <img src={process.env.PUBLIC_URL + "/images/about/subrays-office.svg"} alt="thumbnail" />
                    </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="about-team">
                    <div className="section-heading heading-left">
                        <span className="subtitle">Our Mission</span>
                        <h2>Alone we can do so little; together we can do so much.</h2>  
                        <p>At Subrays, <b>our mission is to bring dreams to life through the art of game development.</b> We strive to create games that not only entertain but also inspire and challenge our players.</p>
                        <p>Our goal is to build game worlds where imagination knows no limits, and where every gamer can find a place to explore, compete, and connect.</p>

                         <Link to="/contact" className="axil-btn btn-large btn-fill-primary">Enquire now</Link>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFive;