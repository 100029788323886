import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import ScrollToTop from './component/scrollToTop/ScrollToTop';
import 'bootstrap/dist/css/bootstrap.min.css';


// Home Pages Import
import CreativeAgency from './pages/CreativeAgency';
// Blog Import
//import BlogGridView from './pages/BlogGrid';
//import BlogCategory from './pages/Category';
//import BlogArchive from './pages/Archive';
//import BlogDetails from './pages/BlogDetails';

// Service 
import ServiceTwo from './pages/ServiceTwo';
import ServiceDetails from './pages/ServiceDetails';
// Project 
import ProjectGridOne from './pages/ProjectGridOne';
import ProjectDetails from './pages/ProjectDetails';

// Pages 
import AboutUs from './pages/AboutUs';
import TeamDetails from './pages/TeamDetails';
import Contact from './pages/Contact';
import ErrorPage from './pages/404';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import GetAnEstimate from './pages/GetAnEstimate';
import ToastComponent from './component/toaster/ToastComponent';

// Css Import
import './assets/scss/app.scss';


const App = () => {
  return (
    <Router>
		<ToastComponent />
		<ScrollToTop>
			<Routes>
				<Route path={process.env.PUBLIC_URL + "/"} element={<CreativeAgency />}/>

				<Route path={process.env.PUBLIC_URL + "/game-development-price-calculator"} element={<GetAnEstimate />}/>



				


				{/* Service */}
				<Route path={process.env.PUBLIC_URL + "/services/"} element={<ServiceTwo />}/>
				<Route path={process.env.PUBLIC_URL + "/service-details/:slug"} element={<ServiceDetails />}/>

				{/* Project  */}
				<Route path={process.env.PUBLIC_URL + "/our-games"} element={<ProjectGridOne />}/>
				<Route path={process.env.PUBLIC_URL + "/project-details/:slug"} element={<ProjectDetails />}/>

				{/* Pages  */}
				<Route path={process.env.PUBLIC_URL + "/about-us"} element={<AboutUs />}/>

				<Route path={process.env.PUBLIC_URL + "/team-details/:slug"} element={<TeamDetails />}/>
				<Route path={process.env.PUBLIC_URL + "/contact"} element={<Contact />}/>
				<Route path={process.env.PUBLIC_URL + "/404"} element={<ErrorPage />}/>
				<Route path={process.env.PUBLIC_URL + "/privacy-policy"} element={<PrivacyPolicy />}/>
				<Route path={process.env.PUBLIC_URL + "/terms-use"} element={<TermsOfUse />}/>

			</Routes>
		</ScrollToTop>
    </Router>

  )
}

export default App;
