import React, { useRef } from "react";

import { toastEmitter } from "../toaster/ToastComponent";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import useRecaptcha from "../../component/Recapcha/useRecaptcha";

const FormOne = () => {
  const form = useRef();
  const { capchaToken, recaptchaRef, handleRecaptcha } = useRecaptcha();

    async function sendEmail(e) {
    e.preventDefault();

    let contactName = document.getElementById('contact-name').value;
    let contactEmail = document.getElementById('contact-email').value;
    let contactPhone = document.getElementById('contact-phone').value;
    //let contactMessage = document.getElementById('contact-message').value;

    let data = {
        "name": contactName,
        "email": contactEmail,
        "message":(window.location.pathname).replace('/service-details/', ''),
        "data": {
            "Source": "Service Detail Page",
            "Phone": contactPhone,
        },
        "g-recaptcha-response": capchaToken
      }

      if (!capchaToken){
        toastEmitter("error", "reCAPTCHA verification failed");
        return;
    }

    try {
        // Send query request with captcha token, email
        const result = await axios.post("https://api.subrays.com/send_mail.php", data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        //console.log(result);

        if (result.data === 'reCAPTCHA verification failed.') {
            toastEmitter('error', "reCAPTCHA verification failed.");

              handleRecaptcha('');
              if (recaptchaRef.current) {
                recaptchaRef.current.reset();
              }
              return;
            }
            if (result.data === 'Invalid JSON input.') {
                toastEmitter('error', "some error occurred.");
    
                  handleRecaptcha('');
                  if (recaptchaRef.current) {
                    recaptchaRef.current.reset();
                  }
                  return;
                }
                if (result.data === 'Mail sent successfully.') {
                    toastEmitter("success", "Query Sent");
        
                      handleRecaptcha('');
                      if (recaptchaRef.current) {
                        recaptchaRef.current.reset();
                      }
                      return;
                    }

                
        
  
    } catch (error) {
        toastEmitter('error', "some error occurred.");
        recaptchaRef.current.reset();
              
    }
      


    
  };

  return (
    <form ref={form} onSubmit={sendEmail} className="axil-contact-form">
      <div className="form-group">
        <label>Name</label>
        <input
            id="contact-name"
          type="text"
          className="form-control"
          name="contact-name"
          required
        />
      </div>
      <div className="form-group">
        <label>Email</label>
        <input
        id="contact-email"
          type="email"
          className="form-control"
          name="contact-email"
          required
        />
      </div>
      <div className="form-group">
        <label>Phone</label>
        <input
        id="contact-phone"
          type="tel"
          className="form-control"
          name="contact-phone"
          required
        />
      </div>
      {/** 
      <div className="form-group mb--40">
        <label>How can we help you?</label>
        <textarea
        id="contact-message"
          className="form-control"
          name="contact-message"
          rows="4"
        ></textarea>
      </div>*/}
      <div className="form-group mb--20" style={{ display: "flex", justifyContent: "center"}} >
        <ReCAPTCHA
          
          ref={recaptchaRef}
          sitekey="6LeeXSIqAAAAACKOOwpauR1sv589Dnk2Mt0sQxBF"
          onChange={handleRecaptcha}
        />
      </div>

      <div className="form-group">
        <button
        disabled={!capchaToken}
          type="submit"
          className="axil-btn btn-fill-primary btn-fluid btn-primary"
          name="submit-btn"
        >
          Get Pricing Now
        </button>
      </div>
    </form>
  );
};

export default FormOne;
