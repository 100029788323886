import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const TermsOfUse = () => {

    return (
        <>
            <SEO title="Terms Of Use" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                title="Terms Of Use"
                page="Terms Of Use"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                <p>Last updated: January 05, 2024</p>
                                <p>By downloading or using the app, you agree to these terms automatically. Please read them carefully before using the app. You are not allowed to copy, modify, or use any part of the app or our trademarks in any way. You are also prohibited from attempting to extract the source code of the app, translating it into other languages, or creating derivative versions. The app, including all trademarks, copyrights, database rights, and other intellectual property rights, remains the property of Subrays Technologies (Private) Limited.</p>

    <h4>Modifications and Charges</h4>
    <p>Subrays Technologies (Private) Limited is dedicated to making the app as useful and efficient as possible. We reserve the right to make changes to the app or its services at any time and for any reason. We will clearly communicate any charges related to the app or its services before you incur any costs.</p>

    <h4>Data Security and Device Requirements</h4>
    <p>The app processes personal data provided by you to deliver our services. It is your responsibility to keep your device and app access secure. We recommend not jailbreaking or rooting your device, as this can make your device vulnerable to malware and could affect the app's functionality.</p>

    <h4>Third-Party Services</h4>
    <p>The app uses third-party services that have their own Terms and Conditions:</p>
    <ul>
        <li><a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Google Play Services</a></li>
        <li><a href="https://www.facebook.com/legal/terms/plain_text_terms" target="_blank" rel="noopener noreferrer">Facebook</a></li>
        <li><a href="https://gameanalytics.com/terms" target="_blank" rel="noopener noreferrer">GameAnalytics</a></li>
    </ul>

    <h4>Limitations of Responsibility</h4>
    <p>Subrays Technologies (Private) Limited cannot be held responsible for the app’s performance without an active internet connection, whether through Wi-Fi or mobile data. You are responsible for any charges related to your mobile data usage, including roaming charges if you use the app outside of your home territory. If you are not the bill payer for the device, you must have received permission from the bill payer to use the app.</p>
    <p>We also cannot be responsible for issues arising from your device running out of battery. Additionally, while we strive to keep the app updated and accurate, we rely on third-party information, and Subrays Technologies (Private) Limited is not liable for any loss resulting from reliance on the app’s functionality.</p>

    <h4>Updates and Termination</h4>
    <p>We may update the app and its requirements periodically. You will need to download updates to continue using the app. Subrays Technologies (Private) Limited does not guarantee that the app will always be compatible with your device or Android version. We may also discontinue the app at any time without notice. Upon termination, you must stop using the app and delete it from your device.</p>

    <h4>Changes to These Terms</h4>
    <p>We may update our Terms of Use from time to time. Please review this page periodically for any changes. We will notify you of any updates by posting the revised Terms of Use on this page.</p>
    <p>These terms are effective as of January 05, 2024.</p>
    <h4>Contact Us</h4>
<p>If you have any questions about this Terms of Use, You can contact us:</p>
<ul>
<li>
<p>By email: hello@subrays.com</p>
</li>
<li>
<p>By visiting this page on our website: <a href="https://subrays.com/contact" rel="noopener noreferrer" target="_blank">https://subrays.com/contact</a></p>
</li>
<li>
<p>By phone number: +92 (040) 203 5022</p>
</li>
</ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <CtaLayoutOne />
            <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default TermsOfUse;