import React, { useState } from "react";

import SEO from "../common/SEO";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import FooterOne from "../common/footer/FooterOne";
import HeaderTwo from "../common/header/HeaderTwo";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import FormFour from "../component/getAnEstimate/FormFour";
import FormFive from "../component/getAnEstimate/FormFive";

import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";

const GetAnEstimate = () => {
  const scrollToSection = (sectionRef) => {
    const element = document.getElementById("comparisonTable");
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  

  const [dataFromCalulator, setDataFromCalulator] = useState(0);
  const [optionsSelected, setOptionsSelected] = useState({});

  function handleDataFromChild(priceCalulated, _optionsSelected) {
    setDataFromCalulator(priceCalulated);
    setOptionsSelected(_optionsSelected);
  }

  return (
    <>
      <SEO title="Game development cost calculator" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderTwo />
        <BreadCrumbOne title="Get an Estimate" page="Get an Estimate" />

        <div className="section section-padding">
          <div className="container">
            <div className="section-heading heading-dark">
              <h2 className="title mb--50">Game Development Cost Calculator</h2>
              <p>
                <b>Estimate game development costs</b> with our intuitive game
                price calculator, tailored to fit your project needs and budget.
              </p>
            </div>
            <div className="col fourStackCard mb--80 mt--100">
              <div className="cardStep">
                <span className="textNumber">1</span>{" "}
                <span>
                  Fill in the required inputs below with relevant info.
                </span>
              </div>
              <div className="cardStep">
                <span className="textNumber">2</span>{" "}
                <span>Press "Calculate" button in order to see the price.</span>
              </div>
              <div className="cardStep">
                <span className="textNumber">3</span>{" "}
                <span>Compare the prices to make right decision.</span>
              </div>
              <div className="cardStep">
                <span className="textNumber">4</span>{" "}
                <span>Contact us to get accurate estimations.</span>
              </div>
            </div>

            <div className="row">
              <div className="formContainer">
                <div className="calculator-form-box shadow-box mb--30">
                  <h3 className="title">Get an Estimate</h3>
                  <FormFour
                    sendDataToParent={handleDataFromChild}
                    _scrollToSection={scrollToSection}
                  />
                </div>
              </div>
            </div>
          </div>
          <ul className="list-unstyled shape-group-12">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/circle-3.png"}
                alt="Circle"
              />
            </li>
          </ul>
        </div>

        <div className="section section-padding bg-color-light  ">
          <div className="topContainerComparison">
            <div className="section-heading heading-dark">
              <p className="subtitle">Versus</p>

              <h2 className="title ">Comparison With Others</h2>
              <p>
                Explore how our <b>game development costs</b> stack up against
                the competition and discover the exceptional value we provide.
              </p>
            </div>
            <div className="comparionTableContainer">
              <div className="comparionTableHeaderRow">
                <span className="comparionTableHeaderCell comparisonCell">
                  Competitors
                </span>
                <span className="comparionTableHeaderCell comparisonCell">
                  Freelancers
                </span>
                <span className="comparionTableHeaderCell comparisonCell">
                  Subrays
                </span>
              </div>
              <div className="comparionTableRow">
                <span className="comparionTableFeatureCell comparisonCell">
                  {" "}
                  Low Development Cost
                </span>
                <span className="comparionTableNormalCell comparisonCell">
                  {" "}
                  <img
                    className="iconsComparison"
                    src={process.env.PUBLIC_URL + "/images/icon/cross-icon.png"}
                    alt="Cross"
                  />
                </span>
                <span className="comparionTableNormalCell comparisonCell">
                  {" "}
                  <img
                    className="iconsComparison"
                    src={process.env.PUBLIC_URL + "/images/icon/tick-icon.png"}
                    alt="Tick"
                  />
                </span>
                <span className="comparionTableNormalCell comparisonCell">
                  {" "}
                  <img
                    className="iconsComparison"
                    src={process.env.PUBLIC_URL + "/images/icon/tick-icon.png"}
                    alt="Tick"
                  />
                </span>
              </div>
              <div className="comparionTableRow">
                <span className="comparionTableFeatureCell comparisonCell">
                  Post-Launch Support
                </span>
                <span className="comparionTableNormalCell comparisonCell">
                  <img
                    className="iconsComparison"
                    src={process.env.PUBLIC_URL + "/images/icon/cross-icon.png"}
                    alt="Cross"
                  />
                </span>
                <span className="comparionTableNormalCell comparisonCell">
                  <img
                    className="iconsComparison"
                    src={process.env.PUBLIC_URL + "/images/icon/cross-icon.png"}
                    alt="Cross"
                  />
                </span>
                <span className="comparionTableNormalCell comparisonCell">
                  <img
                    className="iconsComparison"
                    src={process.env.PUBLIC_URL + "/images/icon/tick-icon.png"}
                    alt="Tick"
                  />
                </span>
              </div>
              <div className="comparionTableRow">
                <span className="comparionTableFeatureCell comparisonCell">
                  Secured With NDA
                </span>
                <span className="comparionTableNormalCell comparisonCell">
                  {" "}
                  <img
                    className="iconsComparison"
                    src={
                      process.env.PUBLIC_URL +
                      "/images/icon/exclamation-icon.png"
                    }
                    alt="exclamation-icon"
                  />
                </span>
                <span className="comparionTableNormalCell comparisonCell">
                  {" "}
                  <img
                    className="iconsComparison"
                    src={process.env.PUBLIC_URL + "/images/icon/cross-icon.png"}
                    alt="Cross"
                  />
                </span>
                <span className="comparionTableNormalCell comparisonCell">
                  {" "}
                  <img
                    className="iconsComparison"
                    src={process.env.PUBLIC_URL + "/images/icon/tick-icon.png"}
                    alt="Tick"
                  />
                </span>
              </div>
              <div className="comparionTableRow">
                <span className="comparionTableFeatureCell comparisonCell">
                  Daily Development Updates
                </span>
                <span className="comparionTableNormalCell comparisonCell">
                  {" "}
                  <img
                    className="iconsComparison"
                    src={process.env.PUBLIC_URL + "/images/icon/cross-icon.png"}
                    alt="Cross"
                  />
                </span>
                <span className="comparionTableNormalCell comparisonCell">
                  {" "}
                  <img
                    className="iconsComparison"
                    src={
                      process.env.PUBLIC_URL +
                      "/images/icon/exclamation-icon.png"
                    }
                    alt="exclamation-icon"
                  />
                </span>
                <span className="comparionTableNormalCell comparisonCell">
                  {" "}
                  <img
                    className="iconsComparison"
                    src={process.env.PUBLIC_URL + "/images/icon/tick-icon.png"}
                    alt="Tick"
                  />
                </span>
              </div>
              <div id="comparisonTable" className="comparionTableRow">
                <span className="comparionTableFeatureCell comparisonCell">
                  {" "}
                  High-Quality Graphics
                </span>
                <span className="comparionTableNormalCell comparisonCell">
                  {" "}
                  <img
                    className="iconsComparison"
                    src={
                      process.env.PUBLIC_URL +
                      "/images/icon/exclamation-icon.png"
                    }
                    alt="Exclamation-icon"
                  />
                </span>
                <span className="comparionTableNormalCell comparisonCell">
                  <img
                    className="iconsComparison"
                    src={process.env.PUBLIC_URL + "/images/icon/cross-icon.png"}
                    alt="Cross"
                  />
                </span>
                <span className="comparionTableNormalCell comparisonCell">
                  {" "}
                  <img
                    className="iconsComparison"
                    src={process.env.PUBLIC_URL + "/images/icon/tick-icon.png"}
                    alt="Tick"
                  />
                </span>
              </div>
              <div className="comparionTableRow">
                <span className="comparionTableFeatureCell comparisonCell">
                  {" "}
                  Dedicated Project Manager
                </span>
                <span className="comparionTableNormalCell comparisonCell">
                  {" "}
                  <img
                    className="iconsComparison"
                    src={process.env.PUBLIC_URL + "/images/icon/tick-icon.png"}
                    alt="Tick"
                  />
                </span>
                <span className="comparionTableNormalCell comparisonCell">
                  <img
                    className="iconsComparison"
                    src={process.env.PUBLIC_URL + "/images/icon/cross-icon.png"}
                    alt="Cross"
                  />
                </span>
                <span className="comparionTableNormalCell comparisonCell">
                  {" "}
                  <img
                    className="iconsComparison"
                    src={process.env.PUBLIC_URL + "/images/icon/tick-icon.png"}
                    alt="Tick"
                  />
                </span>
              </div>
              <div className="comparionTableRow">
                <span className="comparionTableFeatureCell comparisonCell">
                  {" "}
                  Client Satisfaction
                </span>
                <span className="comparionTableNormalCell comparisonCell">
                  {" "}
                  <img
                    className="iconsComparison"
                    src={
                      process.env.PUBLIC_URL +
                      "/images/icon/exclamation-icon.png"
                    }
                    alt="exclamation-icon"
                  />
                </span>
                <span className="comparionTableNormalCell comparisonCell">
                  <img
                    className="iconsComparison"
                    src={process.env.PUBLIC_URL + "/images/icon/cross-icon.png"}
                    alt="Cross"
                  />
                </span>
                <span className="comparionTableNormalCell comparisonCell">
                  {" "}
                  <img
                    className="iconsComparison"
                    src={process.env.PUBLIC_URL + "/images/icon/tick-icon.png"}
                    alt="Tick"
                  />
                </span>
              </div>
              <div className="comparionTableRow">
                <span className="comparionTableFeatureCell comparisonCell">
                  {" "}
                  Experience & Expertise
                </span>
                <span className="comparionTableNormalCell comparisonCell">
                  {" "}
                  <img
                    className="iconsComparison"
                    src={process.env.PUBLIC_URL + "/images/icon/tick-icon.png"}
                    alt="Tick"
                  />
                </span>
                <span className="comparionTableNormalCell comparisonCell">
                  {" "}
                  <img
                    className="iconsComparison"
                    src={
                      process.env.PUBLIC_URL +
                      "/images/icon/exclamation-icon.png"
                    }
                    alt="exclamation-icon"
                  />
                </span>
                <span className="comparionTableNormalCell comparisonCell">
                  {" "}
                  <img
                    className="iconsComparison"
                    src={process.env.PUBLIC_URL + "/images/icon/tick-icon.png"}
                    alt="Tick"
                  />
                </span>
              </div>
              <div className="comparionTableRow">
                <span className="comparionTableFeatureCell comparisonCell">
                  {" "}
                  Rapid Response Time
                </span>
                <span className="comparionTableNormalCell comparisonCell">
                  {" "}
                  <img
                    className="iconsComparison"
                    src={process.env.PUBLIC_URL + "/images/icon/cross-icon.png"}
                    alt="Cross"
                  />
                </span>
                <span className="comparionTableNormalCell comparisonCell">
                  <img
                    className="iconsComparison"
                    src={process.env.PUBLIC_URL + "/images/icon/cross-icon.png"}
                    alt="Cross"
                  />
                </span>
                <span className="comparionTableNormalCell comparisonCell">
                  {" "}
                  <img
                    className="iconsComparison"
                    src={process.env.PUBLIC_URL + "/images/icon/tick-icon.png"}
                    alt="Tick"
                  />
                </span>
              </div>
              <div className="comparionTableRow">
                <span className="comparionTableFeatureCell comparisonCell">
                  {" "}
                  Comprehensive Documentation
                </span>
                <span className="comparionTableNormalCell comparisonCell">
                  {" "}
                  <img
                    className="iconsComparison"
                    src={process.env.PUBLIC_URL + "/images/icon/tick-icon.png"}
                    alt="Tick"
                  />
                </span>
                <span className="comparionTableNormalCell comparisonCell">
                  <img
                    className="iconsComparison"
                    src={process.env.PUBLIC_URL + "/images/icon/cross-icon.png"}
                    alt="Cross"
                  />
                </span>
                <span className="comparionTableNormalCell comparisonCell">
                  {" "}
                  <img
                    className="iconsComparison"
                    src={process.env.PUBLIC_URL + "/images/icon/tick-icon.png"}
                    alt="Tick"
                  />
                </span>
              </div>
              <div className="comparionTableRow">
                <span className="comparionTableFeatureCell comparisonCell">
                  {" "}
                  Milestone-Based Payments
                </span>
                <span className="comparionTableNormalCell comparisonCell">
                  {" "}
                  <img
                    className="iconsComparison"
                    src={
                      process.env.PUBLIC_URL +
                      "/images/icon/exclamation-icon.png"
                    }
                    alt="exclamation-icon"
                  />
                </span>
                <span className="comparionTableNormalCell comparisonCell">
                  <img
                    className="iconsComparison"
                    src={
                      process.env.PUBLIC_URL +
                      "/images/icon/exclamation-icon.png"
                    }
                    alt="exclamation"
                  />
                </span>
                <span className="comparionTableNormalCell comparisonCell">
                  {" "}
                  <img
                    className="iconsComparison"
                    src={process.env.PUBLIC_URL + "/images/icon/tick-icon.png"}
                    alt="Tick"
                  />
                </span>
              </div>
              <div className="comparionTableRow">
                <span className="comparionTableFeatureResultCell comparisonCell">
                  Total Cost
                </span>
                <span className="comparionTableResultCell comparisonCell">
                  {" "}
                  *
                  <TrackVisibility once>
                    {({ isVisible }) => (
                      <span className="number count">
                        {isVisible ? (
                          <CountUp end={dataFromCalulator} duration={1} />
                        ) : null}
                      </span>
                    )}
                  </TrackVisibility>
                  $
                </span>

                {dataFromCalulator > 50000 ? (
                  <span className="comparionTableResultCell comparisonCell">
                    <img
                      style={{width: "30px" ,height: "30px"}}
                      src={
                        process.env.PUBLIC_URL +
                        "/images/icon/danger-icon.png"
                      }
                      alt="danger icon"
                    />
                  </span>
                ) : (
                  <span className="comparionTableResultCell comparisonCell">
                    {" "}
                    *
                    <TrackVisibility once>
                      {({ isVisible }) => (
                        <span className="number count">
                          {isVisible ? (
                            <CountUp
                              end={dataFromCalulator * 0.7}
                              duration={1}
                            />
                          ) : null}
                        </span>
                      )}
                    </TrackVisibility>
                    $
                  </span>
                )}

                <span className="comparionTableResultCell comparisonCell">
                  {" "}
                  *
                  <TrackVisibility once>
                    {({ isVisible }) => (
                      <span className="number count">
                        {isVisible ? (
                          <CountUp end={dataFromCalulator * 0.5} duration={1} />
                        ) : null}
                      </span>
                    )}
                  </TrackVisibility>
                  $
                </span>
              </div>
              

              <div className="mt--80 comparisonTabelTopContainer">
              <FormFive
                    priceCalulated={dataFromCalulator}
                    _optionsSelected={optionsSelected}
                  />
              </div>

              <div className="disclaimerTest mt--80">
                <span>
                  <b>*</b>the price for the game development services you get in
                  the calculator is approximate. Prices are in U.S. dollars.
                </span>
                <br />
                <span>
                  <b>'?'</b>{" "}
                  indicates that the entities may or may not provide this
                  specific feature.
                </span>
                <br />
                <span>
                <img
                      style={{width: "13px" ,height: "13px"}}
                      src={
                        process.env.PUBLIC_URL +
                        "/images/icon/danger-icon.png"
                      }
                      alt="danger icon"
                    />{" "}
                  indicates that the individual may or may not able to handle the
                  specific project.
                </span>
                <br />
                <span>
                  The final price and terms will be determined after all project
                  details are agreed upon. Please{" "}
                  <a href="/contact">get in touch with us</a> to get an accurate
                  estimation.
                </span>
              </div>
            </div>
          </div>
          <ul className="shape-group-6 list-unstyled">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-7.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
                alt="line"
              />
            </li>
          </ul>
        </div>

       

        <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60" />
      </main>
    </>
  );
};

export default GetAnEstimate;
